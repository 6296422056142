html{
    position: relative;
    min-height: 100%;
}
body {
    background: none repeat scroll 0 0 #FFFFFF;
    color: #797979;
    font-family: 'Open Sans',sans-serif;
    font-size: 13px;
    margin-bottom: 60px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    padding: 0 !important;
}

ul li {
    list-style: none;
}

a, a:hover, a:focus {
    text-decoration: none;
    outline: none;
}
::selection {

    background: #e72f81;
    color: #fff;
}
::-moz-selection {
    background: #e72f81;
    color: #fff;
}

#container {
    width: 100%;
    height: 100%;
}


/*login page*/



@media (max-width: 767px){

}

.form-signin {
    max-width: 330px;
    margin: 0 auto;
    background: #ffffff;
}

h2.form-signin-heading {
    margin: 0;
    padding:20px 15px;
    text-align: center;
    color: #2A3542;
    background-color: #ffffff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    margin-top: 50px;
}

h2.form-signin-heading .logo{
    width:100%;
    max-width: 270px;
}

.form-signin .checkbox {
    margin-bottom: 14px;
}
.form-signin .checkbox {
    font-weight: normal;
    color: #b6b6b6;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}
.form-signin .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.form-signin .form-control:focus {
    z-index: 2;
}
.form-signin input[type="text"],
.form-signin input[type="email"],
.form-signin input[type="password"] {
    border: 1px solid #eaeaea;
    box-shadow: none;
}

.form-signin .btn-login {
    background: #141E28;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 20px;
}

.form-signin p {
    text-align: center;
    color: #b6b6b6;
    font-size: 16px;
    font-weight: 300;
}

.form-signin a {
    color: #41cac0;
}

.form-signin a:hover {
    color: #b6b6b6;
}

.login-wrap {
    padding: 20px;
}
.help-block{
    margin-left: 5px;
    margin-right: 5px;    
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #f5f5f5;
}
#footer .container {
    margin: 0 auto;
    padding: 0;
    height: 60px;
    display: table;
}
.container .copy {    
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    color: #797979;
}
.container .copy a{
    color: #2A3542;
    font-size: 13px;
}

.modal-content {
    box-shadow: 0 5px 75px rgba(0, 0, 0, 0.23);
    border: none;
}

.modal .modal-header {
  background: #ffffff;
  color: #2a3542;
  border-bottom: 1px solid #DCDCDC;
}

.modal .modal-header .modal-title {
  color: #2a3542;
}

.modal .modal-header .close {
  margin-top: 0;
  color: #2a3542;
}


.form-control {
    border: 1px solid #e2e2e4;
    box-shadow: none;
    color: #474848;
}


.form-control:focus, #focusedInput {
    border: 1px solid #517397;
    box-shadow: none;
}

.form-horizontal .control-label {
    font-weight: 300;
    font-size: 14px;
    text-align: left;
}

input, textarea, select, button {
    outline: none !important;
}


/*button*/
.btn{
    padding: 8px 12px;
}
.btn-default {
    background-color: #bec3c7;
    border-color: #bec3c7;
    color: #fff;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    background-color: #b0b5b9;
    border-color: #b0b5b9;
    color: #fff;
}

.btn-primary {
    background-color: #41cac0;
    border-color: #41cac0;
    color: #FFFFFF;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-color: #39b2a9;
    border-color: #39b2a9;
    color: #FFFFFF;
}

.btn-success {
    background-color: #78CD51;
    border-color: #78CD51;
    color: #FFFFFF;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
    background-color: #6dbb4a;
    border-color: #6dbb4a;
    color: #FFFFFF;
}

.btn-info {
    background-color: #58c9f3;
    border-color: #58c9f3;
    color: #FFFFFF;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
    background-color: #53bee6;
    border-color: #53BEE6;
    color: #FFFFFF;
}

.btn-warning {
    background-color: #f1c500;
    border-color: #f1c500;
    color: #FFFFFF;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
    background-color: #e4ba00;
    border-color: #e4ba00;
    color: #FFFFFF;
}

.btn-danger {
    background-color: #ff6c60;
    border-color: #ff6c60;
    color: #FFFFFF;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
    background-color: #ec6459;
    border-color: #ec6459;
    color: #FFFFFF;
}

.btn-white {
    box-shadow: none !important;
}

/* ===== blockUI ===== */

.blockUI.blockMsg.blockPage span{
    display: block;
    margin: 0 auto;
}


.blockUI.blockMsg.blockPage span[class*="l-"] {
    display: inline-block;
    background: #fff;
    width: 4px;
    height: 4px;
   
    -moz-border-radius: 100%;
    border-radius: 100%;
    margin: 12px 2px;
    position: relative;
    
    -moz-animation: loader 4s infinite;
    -moz-animation-timing-function: cubic-bezier(0.030, 0.615, 0.995, 0.415);
    -moz-animation-fill-mode: both;
    
    -ms-animation: loader 4s infinite;
    -ms-animation-timing-function: cubic-bezier(0.030, 0.615, 0.995, 0.415);
    -ms-animation-fill-mode: both;
    
}


.blockUI.blockMsg.blockPage span.l-1 {
    -moz-animation-delay: 1s;
    -ms-animation-delay: 1s;
}
    
.blockUI.blockMsg.blockPage span.l-2 {
    -moz-animation-delay: 0.8s;
    -ms-animation-delay: 0.8s;
}
    
.blockUI.blockMsg.blockPage span.l-3 {
    -moz-animation-delay: 0.6s;
    -ms-animation-delay: 0.6s;
}
    
.blockUI.blockMsg.blockPage span.l-4 {
    -moz-animation-delay: 0.4s;
    -ms-animation-delay: 0.4s;   
}
    
.blockUI.blockMsg.blockPage span.l-5 {
    -moz-animation-delay: 0.2s;
    -ms-animation-delay: 0.2s;
}
    
.blockUI.blockMsg.blockPage span.l-6 {
    -moz-animation-delay: 0s;
    -ms-animation-delay: 0s;
}


@-moz-keyframes loader {
    0% { -moz-transform: translateX(-30px); opacity: 0; }
    25% { opacity: 1; }
    50% { -moz-transform: translateX(30px); opacity: 0; }
    100% { opacity: 0; }
}

@-ms-keyframes loader {
    0% { -ms-transform: translateX(-30px); opacity: 0; }
    25% { opacity: 1; }
    50% { -ms-transform: translateX(30px); opacity: 0; }
    100% { opacity: 0; }
}